






















































































































































































import useSelectItems from "@/use/selectItems";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        name: "",
        prefix: "",
        type: "",
        siemensPrefix: "",
        language: "",
        startDate: "",
        endDate: "",
        amount: "",
        currency: "PLN",
      }),
    },
  },

  setup(props, { root, emit }) {
    const form = ref<any>(null);

    const {
      languageItems,
      eventTypeItemsLite,
      currencyItems,
    } = useSelectItems({ root });

    const state = reactive({
      startDateClose: false,
      endDateClose: false,
      endRegistrationDateClose: false,
      nowDate: "",
      minDate: computed(() => props.value.startDate),
      endDate: computed(() => props.value.endDate),
      endRegistrationDate: computed(() => props.value.endRegistrationDate),
      amount: false,
    });

    const nowDate = () => {
      state.nowDate = new Date().toISOString().slice(0, 10);
    };

    onMounted(() => {
      nowDate();
    });

    const rules = {
      name: [(v: string) => !!v || root.$tc("rules.enterName")],
      prefix: [
        (v: string) => !!v || root.$tc("rules.enterPrefix"),
        (v: string) =>
          (v && v.length === 5) || root.$tc("rules.enterValidPrefix"),
      ],
      prefixLength: [
        (v: string) =>
          !!v || v.length === 5 || root.$tc("rules.enterValidPrefix"),
      ],
      type: [(v: string) => !!v || root.$tc("selectEventType")],
      language: [(v: string) => !!v || root.$tc("chooseLanguageVersion")],
      startDate: [
        (v: string) => v >= state.nowDate || root.$tc("rules.eventStartDate"),
      ],
      minEndDate: [
        (v: string) =>
          !state.minDate ||
          v >= state.minDate ||
          root.$tc("rules.selectCorrectDate"),
      ],
      minEndRegistrationDate: [
        (v: string) =>
          !state.minDate ||
          v >= state.minDate ||
          root.$tc("rules.selectCorrectDate"),
      ],
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    watch(
      () => props.value.startDate,
      () => form.value.validate()
    );

    watch(
      () => props.value.prefix,
      (newPrefix: string) => {
        props.value.prefix = newPrefix.toLocaleUpperCase();
        emit("input", props.value);
      }
    );

    watch(
      () => state.amount,
      () => {
        if (!state.amount) {
          props.value.amount = "";
        }
      }
    );

    watch(
      () => props.value.name,
      () => {
        const prefix = props.value.name
          .split(" ")
          .map((i: any) => i.charAt(0))
          .join("")
          .toLocaleUpperCase()
          .replace(/[^a-zA-Z]/g, "")
          .substring(0, 5);
        props.value.prefix = prefix;
      }
    );

    const isLetter = (e: any) => {
      const char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9_-]*$/.test(char)) return true;
      else e.preventDefault();
    };
    return {
      form,
      languageItems,
      eventTypeItemsLite,
      state,
      rules,
      isLetter,
      currencyItems,
    };
  },
});
